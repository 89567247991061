/* ==========================================================================
Footer
========================================================================== */
.bg-footer {
	background: linear-gradient(90deg, rgba(darken($brand-primary, 5%), .9), rgba(lighten($brand-primary, 5%), .9), rgba(darken($brand-primary, 5%), .9)) !important;
}

.footer {
@extend .bg-footer;
color: white;
border-top: solid 4px $brand-second;
text-align: center;
font-size: 1rem;
padding: 0 0 50px 0;
font-size: $font-size-base;
min-height: 25rem;
display: flex;
align-items: center;

h1 {
color: $brand-second;
}

a {
color: $brand-second;
@extend .font-bold;

&:hover {
color: $brand-tierce;
}
}

@media(min-width: $screen-sm-min) {
text-align: left;
}
}

.footer_address {
line-height: 2;
}

.list-footer {

>li {
padding-bottom: 1rem;
}

@media (max-width: $screen-xs-max) {
padding-top: 1rem;
margin-top: 1rem;
border-top: solid 1px $hr-border;
}
}

.list-footer--logo {
margin-top: 1rem;
padding-top: 1rem;

img {
height: 2.5rem;
}
}

.g-play__img {
width: 9rem;
margin-top: 1rem;
}
